import { ViewModelIncludes } from '../includes/ViewModelIncludes';
export class HeaderFooterViewModel {
    constructor() {
        this.vmIncludes = new ViewModelIncludes();
        this.vmIncludes.init();
    }
}
window.onload = () => {
    new HeaderFooterViewModel();
};
